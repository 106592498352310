.main {
    padding-top: 0;

    > p {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }

    table {
        margin-top: 0;
        width: 100%;

        thead {
            th {
                border-bottom: 1px solid #888;
                font-size: 0.9rem;
            }
        }

        &.sticky {
            thead th {
                position: sticky;
                top: 0;
            }
        }

        caption {
            caption-side: top;
            display: table-caption;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
    }

    :global {
        // TODO: this should go within the details component
        .detailsTable {
            thead {
                &.noBorder {
                    th,
                    td {
                        border-bottom: none;
                    }
                }
            }

            .narrowColumn {
                width: 10rem;
                white-space: nowrap;
            }

            td,
            th {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                &:first-child {
                    padding-left: 0.25rem;
                }
                &:last-child {
                    padding-right: 0.25rem;
                }

                &.icon {
                    width: 1rem;
                    white-space: nowrap;

                    .fire {
                        color: #e31937;
                    }

                    .resolved {
                        color: #4a7729;
                    }
                }

                &.dateTime {
                    width: 1rem;
                    white-space: nowrap;
                    font-size: 0.9rem;

                    &.date {
                        padding-right: 0.25rem;
                    }

                    &.time {
                        padding-left: 0.25rem;
                    }
                }
            }

            td {
                background: #f3f3f3;
                border-top: 1px solid #bbb;
                vertical-align: top;
            }

            thead {
                font-weight: 600;
            }
        }

        .icon-active {
            color: #e31937;
        }

        .icon-resolved {
            color: #4a7729;
        }
    }
}

.notFound {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}